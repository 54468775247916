<script setup>
import { ref, onMounted, watch } from 'vue';
import { settingsStore } from "@/stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { useMapTools } from "@/Composables/MapTools.js";

import { measureToolStore }  from "@/stores/MeasureToolStore.js";
import { useMeasureTool }  from "@/Composables/UseMeasureTool.js";
const measureTool = useMeasureTool();
const measureToolData = measureToolStore();
const { goToSelected, hasFollows, goTo, followingLength } = useMapTools();

const app = appStateStore();
const settings = settingsStore();

import interact from 'interactjs'

const sidepanel = ref();

import AirspaceIcon from "@/Icons/AirspaceIcon.vue";
import RuleIcon from "@/Icons/RuleIcon.vue";
import TargetIcon from "@/Icons/TargetIcon.vue";

import { useDrawTargetTool }  from "@/Composables/UseDrawTargetTool.js";
const targetTool = useDrawTargetTool();

import { formatTimeFromStamp, renderDistance } from '../Helpers.js';

import { CogIcon, XMarkIcon, MagnifyingGlassIcon, AdjustmentsVerticalIcon, ArrowUpCircleIcon, Square3Stack3DIcon, PlusIcon, InformationCircleIcon, TrophyIcon, ListBulletIcon, ArrowRightIcon, ArrowLeftIcon, MoonIcon, SunIcon, UserIcon, ChevronLeftIcon, ChevronRightIcon, SparklesIcon, RocketLaunchIcon, ViewfinderCircleIcon, MapPinIcon, TagIcon, CircleStackIcon, PlayIcon, CloudIcon, PresentationChartLineIcon, LockClosedIcon} from "@heroicons/vue/24/outline";

import { useI18n } from 'vue-i18n'
const { t } = useI18n()


var trackLengthMenu = [
	//{label: '5 mins', age: 5, pro: false},
	{label: '10m', age: 10, pro: false},
	{label: '1h', age: 60, pro: false},
	{label: '6h', age: 360, pro: false},
	{label: '12h', age: 720, pro: false},
	{label: '24h', age: 1440, pro: false},
	{label: '2d', age: 2880, pro: true},
	{label: '3d', age: 4320, pro: true},
	{label: '4d', age: 5760, pro: true},
	{label: '6d', age: 8640, pro: true},
	//{label: 'All (Up to 48hr)', age: 2880, pro: false},
];


var recentMenu = [
	{id: 15, label: t('layers-menu.15-label'), alt: t('layers-menu.15-alt'), pro: false},
	{id: 60, label:t('layers-menu.60-label'), alt: t('layers-menu.60-alt'), pro: false},
	{id: 360, label:t('layers-menu.360-label'), alt: t('layers-menu.360-alt'), pro: false},
	{id: 720, label:t('layers-menu.720-label'), alt: t('layers-menu.720-alt'), pro: true},
	{id: 1440, label: t('layers-menu.1440-label'), alt: t('layers-menu.1440-alt'), pro: true},
];

const tippyMeasureTool = ref();
const tippyTargetTool = ref();



// auto hide/close the measure tool info box
watch(() => measureToolData.measureMode, (currentValue, oldValue) => {
	if (currentValue==true) {
		tippyMeasureTool.value?.show();
	} else {
		//console.log('closing tippy');
		tippyMeasureTool.value?.hide();
	}
});


function toggleLabels() {
	if (settings.showLabels && settings.showSecondLine) {
		settings.showLabels = false;
		settings.showSecondLine = false;
		app.redrawLabels++;
	} else if (settings.showLabels && !settings.showSecondLine) {
		settings.showSecondLine = true;
		app.redrawLabels++;
	} else if (!settings.showLabels && !settings.showSecondLine) {
		settings.showLabels = true;
		settings.showSecondLine = false;
		app.redrawLabels++;
	}
}


function stopAllTools() {
	measureTool.disableAddMeasure();
	targetTool.disablePlaceTarget();
}

function openMeasureTool() {

	// if already active, hide it
	if (measureToolData.measureMode) {
		tippyMeasureTool.value.hide();
		stopAllTools(); 
	} else {
		// otherwise open it and start adding points
		stopAllTools(); 
		measureTool.toggleMeasure();
	}
}

function toggleDarkMode() {
	if (settings.darkMode==null) settings.darkMode='light';
	else if (settings.darkMode=='light') settings.darkMode='dark';
	else if (settings.darkMode=='dark') settings.darkMode='light';
}

</script>


<template>

	<div class="flex gap-2 flex-wrap m-2 sm:max-w-[350px] pb-8">

		<button
			:title="$t('app.follow')"
			:class="[settings.pauseFollowing ? 'round-button' : 'round-button-selected !bg-green-700 dark:text-white ']"
			class="cursor-pointer flex gap-1 items-center !py-0"
			@click="settings.toggleFollow()">
			<ViewfinderCircleIcon class="w-4 h-4 " /> 

			<span v-if="!settings.pauseFollowing && followingLength()>0">
				<span>{{followingLength()}}</span>
			</span>

		</button>


		<button
			:title="$t('quick.dark-mode')"
			class="round-button size-8 cursor-pointer" 
			@click="toggleDarkMode()">
			<MoonIcon v-show="settings.darkMode=='light'" class="w-4 h-4 " />
			<SunIcon v-show="settings.darkMode=='dark'" class="w-4 h-4 "/>
		</button>

		<button 
			:title="$t('quick.icons')"
			class="size-8 cursor-pointer "
			:class="[settings.showIcons ? 'round-button-selected' : 'round-button']"
			@click="settings.showIcons = !settings.showIcons">
			<MapPinIcon class="size-4" />
		</button>

		<button 
			:title="$t('quick.labels')"
			class="size-8 cursor-pointer "
			:class="[settings.showLabels ? 'round-button-selected' : 'round-button']"
			@click="toggleLabels()">
			<TagIcon class="size-4" />
		</button>

		<button 
			:title="$t('quick.points-of-interest')"
			class="size-8 cursor-pointer "
			:class="[settings.showLocations ? 'round-button-selected' : 'round-button']"
			@click="settings.showLocations = !settings.showLocations">
			<img src="/images/icons/runway.png" class="w-6">
		</button>


		<button 
			v-if="app.subscribed"
			:title="$t('quick.climb-indicators')"
			class="size-8 cursor-pointer !p-1 items-center justify-center"
			:class="[settings.showThermals ? 'round-button-selected' : 'round-button']"
			@click="settings.showThermals = !settings.showThermals">
			<img src="/images/icons/spiral7.png"  v-show="settings.darkMode=='light'"  class="w-5 h-5 mx-auto">
			<img src="/images/icons/spiral7.png"  v-show="settings.darkMode=='dark'"  class="w-5 h-5 mx-auto">
		</button>

		<button 
			v-if="app.subscribed"
			:title="$t('quick.climb-heights')"
			class="size-8 cursor-pointer !p-1 items-center justify-center"
			:class="[settings.showThermalHeights ? 'round-button-selected' : 'round-button']"
			@click="settings.showThermalHeights = !settings.showThermalHeights">
			<img src="/images/hex-grid.png" class="w-5 h-5 mx-auto">
		</button>


		<button 
			v-if="app.subscribed"
			:title="$t('quick.live-wind')"
			class="size-8 cursor-pointer !p-1 items-center justify-center"
			:class="[settings.showWeatherStations ? 'round-button-selected' : 'round-button']"
			@click="settings.showWeatherStations = !settings.showWeatherStations">
			<img src="/images/icons/wind5.png" class="w-8 mt-1 mx-auto relative">
		</button>

		<button 
			:title="$t('quick.airspace')"
			class="size-8 cursor-pointer items-center justify-center"
			:class="[settings.showAirspace ? 'round-button-selected' : 'round-button']"
			@click="settings.showAirspace = !settings.showAirspace">
			<AirspaceIcon  class="size-6" />
		</button>

		<button 
			:title="$t('layers-menu.rain-radar')"
			class="size-8 cursor-pointer items-center justify-center"
			:class="[settings.showRainRadar ? 'round-button-selected' : 'round-button']"
			@click="settings.showRainRadar = !settings.showRainRadar">
			<CloudIcon  class="size-4" />
		</button>

		<button 
			:title="$t('legend.toggle-chart')" 
			class="size-8 cursor-pointer items-center justify-center"
			:class="[settings.showChart ? 'round-button-selected' : 'round-button']"
			@click="settings.showChart=!settings.showChart;">
			<PresentationChartLineIcon  class="size-4" />
		</button>


		<tippy v-if="app.subscribed" ref="tippyMeasureTool" trigger="manual" :interactive="true" theme="puretrack" placement="bottom" :hideOnClick="false" :sticky="false" class="pointer-events-auto">
			<button 
				:title="$t('measure.measure-tool')"
				class="h-8 cursor-pointer items-center justify-center flex gap-2"
				:class="[measureToolData.measureMode ? 'round-button-selected' : 'round-button']"
				@click="openMeasureTool()">
				<RuleIcon class="size-4" />

				<div v-if="measureToolData.measureDistance">
					{{renderDistance(measureToolData.measureDistance, settings.distanceUnits)}}

					<button :title="$t('app.close')" 
						class=" pill-button"
						:class="[measureToolData.measureMode ? 'pill-button-selected' : 'round-button']" 
						 @click.stop="measureTool.clearMeasure(); tippyMeasureTool.hide(); stopAllTools();">
						<XMarkIcon  class="size-4" />
					</button>
				</div>

			</button>

			<template #content >

				<div class="flex flex-col gap-2 pointer-events-auto  text-black">

					<div 
						class="px-2 py-1  mx-1 mt-1 rounded bg-blue-800 text-white flex gap-1 items-center text-sm">
						<InformationCircleIcon class="size-5" />
						{{ $t('measure.tap-map-to-position') }}
					</div>

					<div v-if="measureToolData.measureMode" class="px-2 py-1 cursor-pointer hover:bg-gray-200" @click="measureTool.toggleMeasure(); tippyMeasureTool.hide();">
						{{ $t('measure.finished') }}
					</div>


					<div v-if="measureToolData.measureDistance" class="px-2 py-1 cursor-pointer hover:bg-gray-200" @click="measureTool.clearMeasure(); measureTool.toggleMeasure(); tippyMeasureTool.hide(); ">
						{{ $t('measure.clear-measuring-tape') }}
					</div>

				</div>

			</template>
		</tippy>




		<tippy v-if="app.subscribed" ref="tippyTargetTool" trigger="click" :interactive="true" theme="puretrack" placement="bottom" :hideOnClick="true" :sticky="true" class="pointer-events-auto">
			<button 
				@click="stopAllTools(); if(!targetTool.hasTarget()) targetTool.togglePlaceTarget();"
				:title="$t('layers-menu.target-tool')"
				class="size-8 cursor-pointer flex items-center justify-center"
				:class="[measureToolData.targetMode ? 'round-button-selected' : 'round-button']"
				>

				<TargetIcon class="size-6" />

			</button>

			<template #content>
				<div class="flex flex-col gap-2 pointer-events-auto  text-black">
					
					<div v-if="!measureToolData.targetMode" class="px-2 py-1 cursor-pointer hover:bg-gray-200" @click="targetTool.togglePlaceTarget();">
						{{ $t('target.place-target') }}
					</div>

					<div v-if="measureToolData.targetMode" 
						class="px-2 py-1 mx-1 mt-1 rounded bg-blue-800 text-white flex gap-1 items-center text-sm">
						<InformationCircleIcon class="size-5" />
						{{ $t('target.click-map-to-position-target') }}
					</div>

					<div v-if="measureToolData.targetMode" class="px-2 py-1 cursor-pointer hover:bg-gray-200" @click="targetTool.togglePlaceTarget();  tippyTargetTool.hide();">
						{{ $t('app.cancel') }}
					</div>

					<div v-if="targetTool.hasTarget()" class="px-2 py-1 cursor-pointer hover:bg-gray-200" @click="targetTool.clearTarget(); tippyTargetTool.hide(); ">
						{{ $t('target.clear-target') }}
					</div>

					<div v-if="targetTool.hasTarget()" class="px-2 py-1 cursor-pointer hover:bg-gray-200" 
						@click="targetTool.goToTarget(); tippyTargetTool.hide();  ">
						{{ $t('target.go-to-target') }}
					</div>

				</div>
			</template>
		</tippy>



		<div class="flex flex-wrap gap-1 items-center text-sm">
			<div>
				{{ $t('layers-menu.max-age') }}
			</div>
			<button v-for="item in recentMenu"
				:title="item.alt"
				:key="item.id"
				:disabled="!app.subscribed && item.pro"
				class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer disabled:opacity-50 flex items-center gap-1"
				@click="settings.filterMaxAge = item.id; app.restartLoop=true;"
				:class="settings.filterMaxAge==item.id ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
					{{ item.label }}
					<LockClosedIcon class="h-4 w-4" v-show="!app.subscribed && item.pro" />
			</button>
		</div>

		<div class="flex flex-wrap gap-1 items-center text-sm">

			<label>{{ $t('layers-menu.selected-track') }}</label> 
			<button v-for="item in trackLengthMenu"
				:key="item.id"
				class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer disabled:opacity-50 flex items-center gap-1"
				:disabled="!app.subscribed && item.pro"
				@click="settings.trackLength = item.age"
				:class="settings.trackLength==item.age ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
					{{ item.label }}
					<LockClosedIcon class="h-4 w-4" v-show="!app.subscribed && item.pro" />
			</button>
		</div>

	</div>

</template>