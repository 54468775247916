<script setup>
import { ref, computed } from 'vue';
import boundingBoxes from '/data/bounding-boxes.json';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { mapStyles } from "@/stores/ProMapStyles.js";
import { pointsStore } from "@/stores/PointsStore.js";
import { messagesStore } from "@/stores/MessagesStore.js";
const messages = messagesStore();

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import ToggleSwitch from '@/PureComponents/ToggleSwitch.vue';

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, CheckIcon } from "@heroicons/vue/24/outline";

const app = appStateStore();
const settings = settingsStore();
const points  = pointsStore();

var labelLengths = [
	{id: 3},
	{id: 8},
	{id: 16}
];

function toggleFollow() {
	settings.legendFollow = !settings.legendFollow;
	if (!settings.legendFollow) {
		settings.following.splice(0);
	}
}

function loadAllTracks() {
	for (var i=0; i<points.withLabelsCount; i++) {
		if (points.withLabelsCount<app.maxTracks) {
			app.selectedTracks.push(points.points[i].key);
			 app.show=null;
		} else {
			messages.error(t('legend.max-tracks-limit', {'maxnumber':app.maxTracks}));
			return;
		}
	}
}
</script>

<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex p-2 sm:px-8 sm:py-4 flex-col w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">

				<div class="flex items-center px-4 pt-4 pb-2 gap-4">

					<button title="close" class="round-button" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

					<h2 class="menu-title flex-grow ">{{ $t('legend.list-settings') }}</h2>

					<button title="Settings" class="round-button" @click="app.showBack.push('legend-settings'); app.show='settings-menu'">
						<CogIcon class="w-6 h-6" />
					</button>

				</div>

				<div class="flex flex-col overflow-y-scroll px-4 pb-6">
					<div class="flex flex-col gap-6 w-full">

						<div  class="flex gap-6 w-full mt-6">

						</div>

						<div  class="menu-section">

							<div class="menu-row">

								<button class="menu-button menu-row-clickable flex-1" 
									@click="loadAllTracks();">
									{{$t('legend.load-all-tracks')}}
								</button>

								<button class="menu-button menu-row-clickable flex-1" 
									@click="app.selectedTracks.splice(0); app.show=null;">
									{{$t('legend.clear-all-tracks')}}
								</button>

							</div>


							<div class="menu-row menu-row-clickable" @click="settings.onlyShowSelected = !settings.onlyShowSelected">

								<div class="whitespace-nowrap flex items-center gap-2">
									<ToggleSwitch  :title="$t('settings-menu.only-show-selected')" id="only-show-selected" v-model="settings.onlyShowSelected"></ToggleSwitch>
									<label for="only-show-selected" class="flex-grow">{{ $t('settings-menu.only-show-selected') }}</label>
								</div>

							</div>
						</div>

						<h3 class="menu-section-title">{{ $t('legend.list-columns') }}</h3>


						<div  class="menu-section">

							<div class="menu-row menu-row-clickable">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="settings.legendTrack = !settings.legendTrack" :title="$t('legend.track-button')" id="legendTrack" v-model="settings.legendTrack"></ToggleSwitch>
									<label for="legendTrack" class="flex-grow">{{ $t('legend.track-button') }}</label>
								</div> 
							</div>

							<div class="menu-row menu-row-clickable">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="toggleFollow()" :title="$t('legend.follow')" id="legendFollow" v-model="settings.legendFollow"></ToggleSwitch>
									<label for="legendFollow" class="flex-grow">{{ $t('legend.follow-button') }}</label>
								</div> 
							</div>

							<div class="menu-row menu-row-clickable">
								<div class="whitespace-nowrap flex items-center justify-between gap-2 w-full ">

									<div class="flex gap-2 items-center">
										<ToggleSwitch @click="settings.legendLabel = !settings.legendLabel" :title="$t('legend.label')" id="legendLabel" v-model="settings.legendLabel"></ToggleSwitch>
										<label for="legendLabel" class="flex-grow">{{ $t('legend.label') }}</label>
									</div>

									<div class="flex gap-2 items-center">
										
										{{$t('legend.label-length')}}

										<button v-for="item in labelLengths"
											:key="item.id"
											class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer"
											@click="settings.legendLabelSize = item.id"
											:class="settings.legendLabelSize==item.id ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
												{{ item.id }}
										</button>
									</div>


								</div> 
							</div>

							<div class="menu-row menu-row-clickable">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="settings.legendAlt = !settings.legendAlt" :title="$t('legend.altitude')" id="legendAlt" v-model="settings.legendAlt"></ToggleSwitch>
									<label for="legendAlt" class="flex-grow">{{ $t('legend.altitude') }}</label>
								</div> 
							</div>
							<div class="menu-row menu-row-clickable">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="settings.legendAgl = !settings.legendAgl" :title="$t('legend.above-ground-level')" id="legendAgl" v-model="settings.legendAgl"></ToggleSwitch>
									<label for="legendAgl" class="flex-grow">{{ $t('legend.above-ground-level') }}</label>
								</div> 
							</div>
							<div class="menu-row menu-row-clickable">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="settings.legendGroundSpeed = !settings.legendGroundSpeed" :title="$t('legend.above-ground-level')" id="legendGroundSpeed" v-model="settings.legendGroundSpeed"></ToggleSwitch>
									<label for="legendGroundSpeed" class="flex-grow">{{ $t('legend.ground-speed') }}</label>
								</div> 
							</div>

							<div class="menu-row menu-row-clickable">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="settings.legendAge = !settings.legendAge" :title="$t('legend.age')" id="legendAge" v-model="settings.legendAge"></ToggleSwitch>
									<label for="legendAge" class="flex-grow">{{ $t('legend.age') }}</label>
								</div> 
							</div>

							<div class="menu-row menu-row-clickable" v-if="app.subscribed">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="settings.legendClimb = !settings.legendClimb" :title="$t('legend.climb-rate')" id="legendClimb" v-model="settings.legendClimb"></ToggleSwitch>
									<label for="legendClimb" class="flex-grow">{{ $t('legend.climb-rate') }}</label>
								</div> 
							</div>

							<div class="menu-row"  v-if="!app.subscribed">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch disabled :title="$t('legend.climb-rate')" id="legendClimb"></ToggleSwitch>
									<label for="legendClimb" class="flex-grow opacity-40">{{ $t('legend.climb-rate') }}</label>
								</div> 

								<a href="/upgrade" v-if="!app.fromAppStore && !app.subscribed" class="flex gap-2 text-sm items-center">
									<button title="Upgrade to Pro" class="round-button">
										<LockClosedIcon class="h-4 w-4" />
									</button>
								</a>
							</div>


							<div class="menu-row menu-row-clickable">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="settings.legendName = !settings.legendName" :title="$t('legend.name')" id="legendName" v-model="settings.legendName"></ToggleSwitch>
									<label for="legendName" class="flex-grow">{{ $t('legend.name') }}</label>
								</div> 
							</div>

							<div class="menu-row menu-row-clickable">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="settings.legendClass = !settings.legendClass" :title="$t('legend.contest-class')" id="legendClass" v-model="settings.legendClass"></ToggleSwitch>
									<label for="legendClass" class="flex-grow">{{ $t('legend.contest-class') }}</label>
								</div> 
							</div>

							<div class="menu-row menu-row-clickable">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="settings.legendSourceIcon = !settings.legendSourceIcon" :title="$t('legend.source-icon')" id="legendSourceIcon" v-model="settings.legendSourceIcon"></ToggleSwitch>
									<label for="legendSourceIcon" class="flex-grow">{{ $t('legend.source-icon') }}</label>
								</div> 
							</div>
							<div class="menu-row menu-row-clickable">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="settings.legendTrackerType = !settings.legendTrackerType" :title="$t('legend.source')" id="legendTrackerType" v-model="settings.legendTrackerType"></ToggleSwitch>
									<label for="legendTrackerType" class="flex-grow">{{ $t('legend.source') }}</label>
								</div> 
							</div>

							<div class="menu-row menu-row-clickable">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="settings.legendTrackerID = !settings.legendTrackerID" :title="$t('legend.source-id')" id="legendTrackerID" v-model="settings.legendTrackerID"></ToggleSwitch>
									<label for="legendTrackerID" class="flex-grow">{{ $t('legend.source-id') }}</label>
								</div> 
							</div>

							<div class="menu-row menu-row-clickable">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="settings.legendStartTime = !settings.legendStartTime" :title="$t('legend.source-id')" id="legendStartTime" v-model="settings.legendStartTime"></ToggleSwitch>
									<label for="legendStartTime" class="flex-grow">{{ $t('legend.start-time') }}</label>
								</div> 
							</div>
							<div class="menu-row menu-row-clickable">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="settings.legendTaskSpeed = !settings.legendTaskSpeed" :title="$t('legend.source-id')" id="legendTaskSpeed" v-model="settings.legendTaskSpeed"></ToggleSwitch>
									<label for="legendTaskSpeed" class="flex-grow">{{ $t('legend.task-speed') }}</label>
								</div> 
							</div>
							<div class="menu-row menu-row-clickable">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="settings.legendTaskLeg = !settings.legendTaskLeg" :title="$t('legend.source-id')" id="legendTaskLeg" v-model="settings.legendTaskLeg"></ToggleSwitch>
									<label for="legendTaskLeg" class="flex-grow">{{ $t('legend.task-leg') }}</label>
								</div> 
							</div>


							<div class="menu-row menu-row-clickable" v-if="app.subscribed">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="settings.legendDistanceToTarget = !settings.legendDistanceToTarget" :title="$t('labels-menu.target-distance')" id="legendDistanceToTarget" v-model="settings.legendDistanceToTarget"></ToggleSwitch>
									<label for="legendDistanceToTarget" class="flex-grow">{{ $t('labels-menu.target-distance') }}</label>
								</div> 
							</div>

							<div class="menu-row menu-row-clickable" v-if="app.subscribed">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="settings.legendBearingToTarget = !settings.legendBearingToTarget" :title="$t('labels-menu.target-bearing')" id="legendBearingToTarget" v-model="settings.legendBearingToTarget"></ToggleSwitch>
									<label for="legendBearingToTarget" class="flex-grow">{{ $t('labels-menu.target-bearing') }}</label>
								</div> 
							</div>


							<div class="menu-row"  v-if="!app.subscribed">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch disabled :title="$t('labels-menu.target-distance')" id="legendDistanceToTarget" ></ToggleSwitch>
									<label for="legendDistanceToTarget" class="flex-grow opacity-40">{{ $t('labels-menu.target-distance') }}</label>
								</div> 

								<a href="/upgrade" v-if="!app.fromAppStore && !app.subscribed" class="flex gap-2 text-sm items-center">
									<button title="Upgrade to Pro" class="round-button">
										<LockClosedIcon class="h-4 w-4" />
									</button>
								</a>
							</div>

							<div class="menu-row"  v-if="!app.subscribed">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch disabled :title="$t('labels-menu.target-bearing')" id="legendDistanceToTarget" ></ToggleSwitch>
									<label for="legendDistanceToTarget" class="flex-grow opacity-40">{{ $t('labels-menu.target-bearing') }}</label>
								</div> 

								<a href="/upgrade" v-if="!app.fromAppStore && !app.subscribed" class="flex gap-2 text-sm items-center">
									<button title="Upgrade to Pro" class="round-button">
										<LockClosedIcon class="h-4 w-4" />
									</button>
								</a>
							</div>





						</div>




						<div  class="menu-section mt-6">

							<div class="menu-row menu-row-clickable">
								<div class="whitespace-nowrap flex items-center gap-2 w-full ">
									<ToggleSwitch @click="settings.legendHideUnknown = !settings.legendHideUnknown" :title="$t('legend.hide-unidentified-labels')" id="legendTrack" v-model="settings.legendHideUnknown"></ToggleSwitch>
									<label for="legendTrack" class="flex-grow">{{ $t('legend.hide-unidentified-labels') }}</label>
								</div> 
							</div>

						</div>



					</div>
				</div>



			</div>
		</div>
	</div>

</template>