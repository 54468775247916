<script setup>
import { ref, computed } from 'vue';
import boundingBoxes from '/data/bounding-boxes.json';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { mapStyles } from "@/stores/ProMapStyles.js";

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import ToggleSwitch from '@/PureComponents/ToggleSwitch.vue';

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, CheckIcon } from "@heroicons/vue/24/outline";

const app = appStateStore();
const settings = settingsStore();


</script>

<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex p-2 sm:px-8 sm:py-4 flex-col w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">

				<div class="flex items-center px-4 pt-4 pb-2 gap-4">

					<button :title="$t('app.back')" class="round-button" @click="app.show=app.showBack.pop()">
						<ArrowLeftIcon class="w-6 h-6" />
					</button>

					<h2 class="menu-title flex-grow ">{{ $t('labels-menu.labels') }}</h2>

					<button title="close" class="round-button" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

				</div>


				<div class="flex flex-col overflow-y-scroll px-4 pb-4 pt-6">
					<div class="flex flex-col gap-6 w-full">


						<div class="flex gap-4 justify-center items-center">
							
							<ToggleSwitch v-model="settings.showSecondLine" @click="settings.showSecondLine = !settings.showSecondLine; app.redrawLabels++" id="showSecondLine"></ToggleSwitch>


							<h3 class="menu-section-title">{{$t('labels-menu.second-line')}}</h3>
						</div>

						<div class="menu-section ">

							<div class="menu-row">

								<div class="whitespace-nowrap flex items-center gap-2">
									<ToggleSwitch v-model="settings.showHeightLabel" @click="settings.showHeightLabel = !settings.showHeightLabel; app.redrawLabels++" id="showHeightLabel"></ToggleSwitch>
									<label for="showHeightLabel" class="flex-grow cursor-pointer">{{ $t('settings-menu.altitude') }}</label> 
								</div>
							</div>

							<div class="menu-row">

								<div class="whitespace-nowrap flex items-center gap-2">
									<ToggleSwitch v-model="settings.showAGLLabel" @click="settings.showAGLLabel = !settings.showAGLLabel; app.redrawLabels++" id="showAGLLabel"></ToggleSwitch>
									<label for="showAGLLabel" class="flex-grow cursor-pointer">{{ $t('settings-menu.altitude-agl') }}</label> 
								</div>
							</div>

							<div class="menu-row">

								<div class="whitespace-nowrap flex items-center gap-2">
									<ToggleSwitch v-model="settings.showVSpeedLabel" @click="settings.showVSpeedLabel = !settings.showVSpeedLabel; app.redrawLabels++" id="showVSpeedLabel"></ToggleSwitch>
									<label for="showVSpeedLabel" class="flex-grow cursor-pointer">{{ $t('settings-menu.vertical-speed') }}</label> 
								</div>
							</div>


							<div class="menu-row">

								<div class="whitespace-nowrap flex items-center gap-2">
									<ToggleSwitch v-model="settings.showSpeedLabel" @click="settings.showSpeedLabel = !settings.showSpeedLabel; app.redrawLabels++" id="showSpeedLabel"></ToggleSwitch>
									<label for="showSpeedLabel" class="flex-grow cursor-pointer">{{ $t('settings-menu.speed') }}</label> 
								</div>
							</div>



							<div class="menu-row justify-between" v-if="app.subscribed">

								<div class="whitespace-nowrap flex items-center gap-2">
									<ToggleSwitch v-model="settings.showNameLabel" @click="settings.showNameLabel = !settings.showNameLabel; app.redrawLabels++" id="showNameLabel"></ToggleSwitch>
									<label for="showNameLabel" class="flex-grow cursor-pointer">{{ $t('labels-menu.name') }}</label> 
								</div>
							</div>


							<div class="menu-row justify-between" v-if="app.subscribed">

								<div class="whitespace-nowrap flex items-center gap-2">
									<ToggleSwitch v-model="settings.showClassLabel" @click="settings.showClassLabel = !settings.showClassLabel; app.redrawLabels++" id="showClassLabel"></ToggleSwitch>
									<label for="showClassLabel" class="flex-grow cursor-pointer">{{ $t('labels-menu.class') }}</label> 
								</div>
							</div>


							<div class="menu-row justify-between" v-if="app.subscribed">

								<div class="whitespace-nowrap flex items-center gap-2">
									<ToggleSwitch v-model="settings.showSourceLabel" @click="settings.showSourceLabel = !settings.showSourceLabel; app.redrawLabels++" id="showSourceLabel"></ToggleSwitch>
									<label for="showSourceLabel" class="flex-grow cursor-pointer">{{ $t('labels-menu.source') }}</label> 
								</div>
							</div>


							<div class="menu-row justify-between" v-if="app.subscribed">

								<div class="whitespace-nowrap flex items-center gap-2">
									<ToggleSwitch v-model="settings.showRegoLabel" @click="settings.showRegoLabel = !settings.showRegoLabel; app.redrawLabels++" id="showRegoLabel"></ToggleSwitch>
									<label for="showRegoLabel" class="flex-grow cursor-pointer">{{ $t('labels-menu.rego') }}</label> 
								</div>
							</div>

							
							<div class="menu-row justify-between" v-if="app.subscribed">

								<div class="whitespace-nowrap flex items-center gap-2">
									<ToggleSwitch v-model="settings.showTargetDistanceLabel" @click="settings.showTargetDistanceLabel = !settings.showTargetDistanceLabel; app.redrawLabels++" id="showTargetDistanceLabel"></ToggleSwitch>
									<label for="showTargetDistanceLabel" class="flex-grow cursor-pointer">{{ $t('labels-menu.target-distance') }}</label> 
								</div>
							</div>
							<div class="menu-row justify-between" v-if="app.subscribed">

								<div class="whitespace-nowrap flex items-center gap-2">
									<ToggleSwitch v-model="settings.showTargetBearingLabel" @click="settings.showTargetBearingLabel = !settings.showTargetBearingLabel; app.redrawLabels++" id="showTargetBearingLabel"></ToggleSwitch>
									<label for="showTargetBearingLabel" class="flex-grow cursor-pointer">{{ $t('labels-menu.target-bearing') }}</label> 
								</div>
							</div>
							



							<div class="menu-row justify-between" v-if="app.subscribed">

								<div class="whitespace-nowrap flex items-center gap-2">
									<ToggleSwitch v-model="settings.showThermalClimbLabel" @click="settings.showThermalClimbLabel = !settings.showThermalClimbLabel; app.redrawLabels++" id="showThermalClimbLabel"></ToggleSwitch>
									<label for="showThermalClimbLabel" class="flex-grow cursor-pointer">{{ $t('legend.climb-rate') }}</label> 
								</div>
							</div>


							<div class="menu-row justify-between" v-if="!app.subscribed">

								<div class="whitespace-nowrap flex items-center gap-2">
									<ToggleSwitch disabled v-model="settings.showThermalClimbLabel" @click="app.redrawLabels++" id="showThermalClimbLabel"></ToggleSwitch>
									<label for="showThermalClimbLabel" class="flex-grow cursor-pointer disabled-text">{{ $t('legend.climb-rate') }}</label> 
								</div>

								<a href="/upgrade" v-if="!app.fromAppStore && !app.subscribed" class="flex gap-2 text-sm items-center">
									<button title="Upgrade to Pro" class="round-button">
										<LockClosedIcon class="h-4 w-4" />
									</button>
								</a>

							</div>

						</div>






						<h3 class="menu-section-title">{{$t('labels-menu.label-options')}}</h3>

						<div class="menu-section ">

							<div class="menu-row">

								<div class="whitespace-nowrap flex items-center gap-2">
									<ToggleSwitch v-model="settings.showUnidentifiedLabels" @click="settings.showUnidentifiedLabels = !settings.showUnidentifiedLabels; app.redrawLabels++" id="showUnidentifiedLabels"></ToggleSwitch>
									<label for="showUnidentifiedLabels" class="flex-grow cursor-pointer">{{ $t('labels-menu.show-unidentified') }}</label> 
								</div>
							</div>


							<div class="menu-row">

								<div class="whitespace-nowrap flex items-center gap-2">
									<ToggleSwitch v-model="settings.showWobbleLabels" @click="settings.showWobbleLabels = !settings.showWobbleLabels; " id="showWobbleLabels"></ToggleSwitch>

									<div class="flex flex-col gap-1">

										<label for="showWobbleLabels" class="flex-grow cursor-pointer">{{ $t('labels-menu.show-wobble-labels') }}</label>
										<div class="caption">{{ $t('labels-menu.wobble-warning') }}</div>

									</div>
								</div>
							</div>

							<div class="menu-row">

								<div class="whitespace-nowrap flex items-center gap-2">
									{{ $t('labels-menu.wobble-max') }}

									<input type="number" class="input w-[6em]" size="5" v-model="settings.maxWobbleLabels" min="10" max="1000">
								</div>
							</div>


						</div>






					</div>

				</div>

			</div>


		</div>
	</div>

</template>