<script setup>
import { ref, onMounted, watch } from 'vue';

import { settingsStore } from "@/stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { pointsStore } from "@/stores/PointsStore.js";
import { pointTypesStore } from "@/stores/PointTypesStore.js";

const app = appStateStore();
const settings = settingsStore();

const selectedAircraftDetails = ref(null);
const loadingAircraftDetails = ref(false);
const selectedAircraftImage = ref(null);
const loadingAircraftImage = ref(false);
const loadingTargetDetails = ref(false);
const pointTypes  = pointTypesStore();

import { XMarkIcon, PhotoIcon, LockClosedIcon } from "@heroicons/vue/24/outline";

const props = defineProps(['point']);


watch(() => props.point.rego, (currentValue, oldValue) => {
	//console.log('rego changed');
	getAircraft();
});


onMounted(() => {
	getAircraft();
});

function getAircraft() {

	selectedAircraftDetails.value = null;
	selectedAircraftImage.value = null;

	// not an aircraft
	if (!props.point.rego) return;

	loadingAircraftDetails.value = true;
	if (app.subscribed) loadingAircraftImage.value = true;

	axios.get('/api/aircraft/' + props.point.rego)
	.then(function(response) {
		console.log('fetched aircraft data');
		console.log(response.data.data);
		selectedAircraftDetails.value = response.data.data;
		loadingAircraftDetails.value = false;
	}).catch(function (error) {
		loadingAircraftDetails.value = false;
	});

	// try loading the image
	// https://www.jetphotos.com/api/json/quicksearch.php?term=zk-gop

	var image_details = null;

	if (app.subscribed) {

		axios.get('/api/aircraft/image/' + props.point.rego)
		.then(function(response) {
			loadingAircraftImage.value = false;
			// console.log('fetched aircraft image');
			// console.log(response.data.data);
			if (response.data.data.filename) {
				selectedAircraftImage.value = response.data.data.filename;
			}
		}).catch(function (error) {
			loadingAircraftImage.value = false;
			// console.log('failed to get image');
		});

	}
}

</script>


<template>

<div class="flex flex-col gap-2">

	<div class="flex flex-wrap gap-2 items-baseline" >
		<div  class="whitespace-nowrap flex-1">
			<div class="infobox-label">{{$t('selected.model')}}</div>
			<div v-if="selectedAircraftDetails">{{selectedAircraftDetails.model}}</div>
		</div>

		<div class="whitespace-nowrap flex-1">
			<div class="infobox-label">{{$t('selected.manufacturer')}}</div>
			<div v-if="selectedAircraftDetails">{{selectedAircraftDetails.manufacturer}}</div>
		</div>
	</div>


	<div v-if="point.rego && !selectedAircraftImage" class="rounded-lg p-4 bg-gray-500 text-center text-gray-300 max-w-[300px] flex gap-2 items-center shadow">


		<PhotoIcon class="h-8 w-8 text-gray-400 " />

		<div v-if="app.subscribed">
			<div v-if="loadingAircraftImage">{{$t('app.loading')}}</div>
			<div v-if="!loadingAircraftImage">{{$t('selected.no-image-found')}}</div>
		</div>

		<div v-if="!app.subscribed">
			<a v-if="!app.fromAppStore" href="/upgrade" class=" flex justify-center items-center"><LockClosedIcon class="h-4 w-4 mr-2" /> {{$t('app.upgrade-to-pro')}}</a>
			<LockClosedIcon v-if="app.fromAppStore" class="h-4 w-4 mx-auto" />
		</div>

	</div>

	<div v-if="point.rego && selectedAircraftImage && app.subscribed">
		<a :href="'https://www.jetphotos.com/registration/' + point.rego" class="max-w-[300px] h-auto bg-gray-500 block  rounded-lg overflow-hidden shadow">
			<img :src="'https://cdn.jetphotos.com/640/' + selectedAircraftImage + '?v=0'" class=" ">
		</a>
	</div>

</div>

</template>