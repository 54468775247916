<script setup>

import { ref, watch } from 'vue';

import { settingsStore } from "@/stores/SettingsStore.js";
const settings = settingsStore();
import { appStateStore } from "@/stores/AppStateStore.js";
const app = appStateStore();
import { useMapTools } from "@/Composables/MapTools.js";
const { goTo, goToSelected } = useMapTools();
import { messagesStore } from "@/stores/MessagesStore.js";
const messages = messagesStore();
import { pointsStore } from "@/stores/PointsStore.js";
const points  = pointsStore();

import { useDrawCompTasks } from "@/Composables/useDrawCompTasks.js";
const drawTasks = useDrawCompTasks();

import { XMarkIcon, TrophyIcon, ChevronDoubleLeftIcon,  ChevronDoubleRightIcon, FunnelIcon, Bars3Icon } from '@heroicons/vue/24/outline';

var loading = ref(0);
var tasksLoading = ref(0);
var comp = ref(null);
var tasks = ref(null);

const props = defineProps(['slug']);
const open = ref(true);

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

var loadTaskStatsTimer = null;

// watch for slug changes

watch(() => props.slug, (currentValue, oldValue) => {
	app.comp=null;
	app.compMembers=null;
	loadComp(); // load the new comp
});


function loadComp() {

	loading.value++;

	axios.get('/api/comps/slug/' + props.slug).then(function(response) {

		loading.value--;
		comp.value = response.data.data;
		app.comp = comp.value;
		app.compMembers = comp.value.members;
		drawTasks.firstLoad.value = true;

		// show all by default
		for (var i=0; i<app.comp.classes.length; i++) {
			app.comp.classes[i].showTask=true;
		}

		// enable the contest columns
		settings.legendName = true;
		settings.legendClass = true;

		loadTasks();

	}).catch(function (error) {
		loading.value--;

		if (error?.response?.data?.message) {
			messages.error(error?.response?.data?.message);
		} else {
			messages.error("Failed to load contest");
		}

	});
}

function loadTasks() {
	//console.log(comp.value.id);
	tasksLoading.value++;
	if (!comp.value?.id) return;

	if (app.tasks) {
		//console.log('removing existing tasks');
		drawTasks.removeTasks(app.tasks);
	}

	axios.get('/api/comps/' + comp.value.id + '/latest-tasks').then(function(response) {
		// console.log('Loaded');
		tasksLoading.value--;
		drawTasks.removeTasks(app.tasks);

		tasks.value = response.data.data;
		app.tasks = tasks.value;

		if (app.tasks.length==0)
		{
			messages.warning(t('contests-menu.no-tasks-available'));
		}

		drawTasks.showTasks();
		loadTaskStats();


	}).catch(function (error) {
		//console.log(error);
		tasksLoading.value--;

		if (error?.response?.data?.message) {
			messages.error(error?.response?.data?.message);
		} else {
			messages.error("Failed to load contest tasks");
		}

	});
}


function loadTaskStats() {

	// console.log('loading task stats');
	let taskIDs = [];
	for (var i=0; i<app.tasks.length; i++) {
		taskIDs.push(app.tasks[i].id);
	}

	axios.get('/api/task-stats/?tasks=' + taskIDs.join(',')).then(function(response) {

		app.compStats = response.data.data;

		// stats automatically get added in, in the points store.
		points.addTaskStats();

	}).catch(function (error) {
		// console.log(error);

		if (error?.response?.data?.message) {
			messages.error(error?.response?.data?.message);
		} else {
			messages.error("Failed to load tasks stats");
		}

	});
}


function clickFollow() {
	if (settings.followGroup==true) {
		settings.pauseFollowing=false;
		goToSelected();
	} else {
		settings.pauseFollowing=true;
	}
}

function copyUrl() {
	copyToClipboard(comp.value.url); 
	messages.success('Link to contest copied to clipboard');
	tippyCompRef.value.hide();
}

loadComp();
settings.filterComp=true;



function closeComp() {

	if (app.tasks) {
		//console.log('removing existing tasks');
		drawTasks.removeTasks(app.tasks);
	}
	settings.legendName = false;
	settings.legendClass = false;
	settings.filterComp = false;
	app.comp=null;
	app.compSlug=null;
	app.restartLoop=true;
}


loadTaskStatsTimer = setInterval(loadTaskStats, 60*1000);

</script>

<template>

	<button v-if="app.compSlug" @click="app.show='contest-menu'" :title="$t('contests-menu.contest-button')"  class="ui-pill pl-1 pr-1 py-1 self-center  text-white bg-orange-700 w-full">

		<div class="flex items-center gap-2">
			<button @click.stop="closeComp()"  :title="$t('app.close')"
				class="pill-button flex-shrink-0">
				<XMarkIcon class="size-4 text-orange-700" />
			</button>
			
			<TrophyIcon class="w-5 h-5  flex-shrink-0 text-white opacity-40" />

			<div v-if="!comp || loading>0" class="flex gap-2 items-center justify-center w-full mr-16">
				<LoadingHorizontal ></LoadingHorizontal>
			</div>

			<div v-if="comp && loading==0" class="whitespace-nowrap overflow-hidden flex flex-grow justify-center" v-show="open">
				<div class="max-w-[300px] overflow-hidden overflow-ellipsis ">{{comp.name}}</div>
			</div>

			<button v-if="comp && loading==0" :title="$t('contests-menu.filter-to-contest')" 
				class=" pill-button "
				:class="[settings.filterComp ? 'pill-button-selected' : '']" 
				@click.stop="settings.filterComp=!settings.filterComp; app.restartLoop=true;">
				<FunnelIcon class="size-4 text-orange-700"  />
			</button>

			<button v-if="comp && loading==0" :title="$t('app.menu')"
				class="pill-button flex-shrink-0"
				@click="app.show='contest-menu'">
				<Bars3Icon class="size-4 text-orange-700" />
			</button>
		</div>
	</button>

	<div class="px-4" v-if="app.comp?.classes?.length>1">
		<div
		v-if="comp"
		class="flex mx-auto items-center justify-center gap-4 text-sm bg-orange-700 mx-4 py-1 px-2 rounded-b-lg max-w-fit text-sm">
			<label :for="'clss_'+clss.id" v-for="clss in app.comp.classes" :key="clss.id" 
			class="whitespace-nowrap overflow-clip overflow-ellipsis text-white" >
				<input :id="'clss_'+clss.id" type="checkbox" 
				class="input " 
				v-model="clss.showTask" 
				@change="drawTasks.showTasks()"> 
				{{clss.name}}
			</label>

		</div>
	</div>

</template>
