<script setup>
import { ref, computed } from 'vue';
import boundingBoxes from '/data/bounding-boxes.json';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { mapStyles } from "@/stores/ProMapStyles.js";

import ToggleSwitch from '@/PureComponents/ToggleSwitch.vue';

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, CheckIcon, ChevronDownIcon, ChevronRightIcon, TrophyIcon, StarIcon } from "@heroicons/vue/24/outline";

const app = appStateStore();
const settings = settingsStore();

import { renderTimeAgo, renderTimeAgoFull, formatDateTime, apiDateToJS, getDaysToGo } from '../Helpers.js';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
TimeAgo.setDefaultLocale(en.locale)
TimeAgo.addLocale(en);
const timeAgo = new TimeAgo('en');


var loadingContests = ref(0);
var loadingComps = ref(0);
var contests = ref(null); //soaring spot comps using old system
var contests_upcoming = ref(null); //soaring spot comps using old system
var comps = ref(null); // new comps list


// function loadContests() {
// 	//console.log('loading contests');

// 	loadingContests.value++;

// 	window.axios.get('/api/soaringspot/contests').then(function (response) {
// 		contests.value = response.data.data?.inprogress?.contests;
// 		contests_upcoming.value = response.data.data?.upcoming?.contests;

// 		loadingContests.value--;

// 		contests.value.inprogress.show=true;
// 	}).catch(error => {
// 		console.log(error.errors);
// 		loadingContests.value--;
// 	});
// 	return;
// }

function loadComps() {
	//console.log('loading contests');

	loadingComps.value++;

	window.axios.get('/api/comps?current=true').then(function (response) {
		comps.value = response.data.data;
		console.log(comps.value);

		loadingComps.value--;

		//comps.value.inprogress.show=true;
	}).catch(error => {
		console.log(error.errors);
		loadingComps.value--;
	});
}

function addContest(contest) {
	console.log('add contest called');
	console.log(contest);
	settings.contestID = contest.id;
	app.show=null;
	settings.legendName = true;
	settings.legendClass = true;
}

//loadContests();
loadComps();

function openComp(comp) {
	app.compSlug = comp.slug;
	app.show=null;
	settings.sideBarPage = 'list';
}

</script>

<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex p-2 sm:py-4 sm:px-8 flex-col w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">

				<div class="flex items-center px-4 pt-4 pb-2 gap-4">

					<button title="close" class="round-button" @click="app.show='puretrack-menu'">
						<ArrowLeftIcon class="w-6 h-6" />
					</button>

					<h2 class="menu-title flex-grow flex gap-1 items-center justify-center"> 
						<TrophyIcon class="w-6 h-6 min-w-6 opacity-60 inline mr-1" />
						{{ $t('contests-menu.contests') }}
					</h2>

					<button title="close" class="round-button" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

				</div>

				<div class="flex flex-col overflow-y-scroll px-4 pb-4 pt-6 gap-4">

					<div class="text-sm text-center text-gray-600 dark:text-gray-300">Note. Dates might be out by 1 day due to timezones</div>

					<div  class="menu-section">

						<div class="menu-row" v-if="loadingComps>0">
							<div class="flex items-center gap-2 mx-auto p-2">
								<LoadingHorizontal ></LoadingHorizontal>
							</div>
						</div>

												<button v-for="comp in comps" 
							:key="comp.id"
							class="menu-row menu-row-clickable flex items-center cursor-pointer justify-between w-full"
							@click="openComp(comp)"
							v-show="getDaysToGo(apiDateToJS(comp.end))>=-1"
							>

							<div class="shrink-0">
								<img v-if="comp.source=='soaringspot'" src="/images/soaringspot-logo.png" width="40" height="40" alt="SoaringSpot">
								<img v-if="comp.source=='ssa'" src="/images/ssa-logo.webp" width="40" height="40" alt="Soaring Society of America">
							</div>

							<div class="flex flex-col flex-grow gap-1">
								<div class="flex items-center gap-1 text-left" :class="[comp.feature ? 'font-bold ': '']">
									<StarIcon class="size-4 " v-if="comp.feature"></StarIcon>
									{{comp.name}}
								</div> 
								<div class="text-sm flex items-center gap-2 text-gray-600 dark:text-gray-400 text-left">

									<div>
										{{formatDateTime(comp.start, false, false)}} 
										-
										{{formatDateTime(comp.end, false)}}
									</div>
									
								</div>
								<div class="flex flex-wrap items-center gap-2">
									<div v-if="getDaysToGo(apiDateToJS(comp.start))>0 && getDaysToGo(apiDateToJS(comp.start))<7" class="text-xs font-bold text-white bg-yellow-500 rounded-full py-1 px-2 whitespace-nowrap w-fit">
										{{getDaysToGo(apiDateToJS(comp.start)) }} day<span v-show="getDaysToGo(apiDateToJS(comp.start))!=1">s</span> to go
									</div>
									<div v-if="getDaysToGo(apiDateToJS(comp.start))<=0 && getDaysToGo(apiDateToJS(comp.end))>=0" class="text-xs font-bold text-white bg-green-600 rounded-full py-1 px-2 whitespace-nowrap w-fit">
										{{getDaysToGo(apiDateToJS(comp.end)) }} day<span v-show="getDaysToGo(apiDateToJS(comp.end))!=1">s</span> remain
									</div>
									<div v-if="comp.feature" class="text-xs font-bold text-white bg-orange-600 rounded-full py-1 px-2 whitespace-nowrap w-fit flex items-center gap-1">
										<StarIcon class="size-4"></StarIcon>
										{{ $t('contests-menu.featured') }}
									</div>
								</div>
							</div>

							<div class="flex items-center gap-2">

								<!-- <div v-if="comp.start">{{ renderTimeAgo(comp.start, app.currentTime) }}</div> -->

								<button :title="$t('puretrack-menu.change-mode')" class="round-button">
									<ArrowRightIcon class="w-3 h-3" />
								</button>
							</div>
						</button>

						<div class="menu-row flex items-center text-center text-gray-500 dark:text-gray-600" v-if="loadingComps<=0 && (comps==null || comps.length==0)">
							{{ $t('app.none') }}
						</div>

					</div>




<!--

					<h3 class="menu-section-title">SoaringSpot (old system, as a backup)</h3>

					<div  class="menu-section">

						<div class="menu-row" v-if="loadingContests>0">
							<div class="flex items-center gap-2 mx-auto p-2">
								<LoadingHorizontal ></LoadingHorizontal>
							</div>
						</div>

						<a v-for="comp in contests" 
							:key="comp.id"
							@click = "addContest(comp)"
							class="menu-row menu-row-clickable flex justify-between cursor-pointer py-1 px-2">
							{{comp.name}}


							<button :title="$t('puretrack-menu.change-mode')" class="round-button">
								<ArrowRightIcon class="w-3 h-3" />
							</button>
						</a>

						<div class="menu-row flex items-center text-center text-gray-500 dark:text-gray-600" v-if="loadingContests<=0 && (contests==null || contests.length==0)">
							{{ $t('app.none') }}
						</div>

					</div>

					<h3 class="menu-section-title">SoaringSpot Upcoming (old!)</h3>

					<div  class="menu-section">

						<div class="menu-row" v-if="loadingContests>0">
							<div class="flex items-center gap-2 mx-auto p-2">
								<LoadingHorizontal ></LoadingHorizontal>
							</div>
						</div>

						<a v-for="comp in contests_upcoming" 
							:key="comp.id"
							@click = "addContest(comp)"
							class="menu-row menu-row-clickable flex justify-between cursor-pointer py-1 px-2">
							
							{{comp.name}}


							<button :title="$t('puretrack-menu.change-mode')" class="round-button">
								<ArrowRightIcon class="w-3 h-3" />
							</button>

						</a>

						<div class="menu-row flex items-center text-center text-gray-500 dark:text-gray-600" v-if="loadingContests<=0 && (contests==null || contests.length==0)">
							{{ $t('app.none') }}
						</div>

					</div>
 -->

				</div>

			</div>


		</div>
	</div>

</template>