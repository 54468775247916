<script setup>
import { ref, computed } from 'vue';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { mapStyles } from "@/stores/ProMapStyles.js";

import { messagesStore } from "@/stores/MessagesStore.js";
const messages = messagesStore();

import { pointsStore } from "@/stores/PointsStore.js";
const points  = pointsStore();

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import ToggleSwitch from '@/PureComponents/ToggleSwitch.vue';

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, CheckIcon, ArrowUpOnSquareIcon, DocumentDuplicateIcon, ArrowRightStartOnRectangleIcon, BellAlertIcon, PencilIcon, UserIcon, WindowIcon, LockOpenIcon, PlusCircleIcon, MapPinIcon } from "@heroicons/vue/24/outline";

import { csrf, renderAlertDescription } from '../Helpers.js';


const app = appStateStore();
const settings = settingsStore();


function importOGN() {

	window.axios.post('/api/ogn/import').then(function (response) {
		messages.success('OGN Imported Triggered');
	});
	// window.axios.get('/api/admin/start-import-flarm-job').then(function (response) {
	// 	messages.success('OGN Imported Triggered');
	// });
}

function importADSB() {
	console.log('import ADSB');

	window.axios.get('/api/admin/start-import-adsb-job').then(function (response) {
		messages.success('Load ADSB Job Triggered');
	}).catch(error => {
		console.log(error);
	});
}



</script>

<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex p-2 sm:px-8 sm:py-4 flex-col w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">

				<div class="flex items-center px-4 pt-4 pb-2 gap-4">

					<button title="close" class="round-button" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

					<h2 class="menu-title flex-grow ">
						<UserIcon class="inline size-6" />
						{{ $t('user.your-account') }} 
					</h2>

					<button title="close" class="round-button opacity-0" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

				</div>


				<div class="flex flex-col overflow-y-scroll px-4 pb-6 pt-6">
					<div class="flex flex-col gap-6 w-full">


						<div class="text-center" v-if="app.user">
							<p class="text-xl">{{app.user.name}}</p>
							<p>{{app.user.email}}</p>
						</div>


						<div class="menu-section" v-if="!app.user">

							<a class="menu-row menu-row-clickable w-full" 
								href="/login">

								<div class="flex flex-col gap-1 flex-grow">
									<div>{{$t('user.login')}}</div>
								</div>


								<button :title="$t('user.login')" class="round-button">
									<ArrowRightIcon class="w-3 h-3" />
								</button>
							</a>

							<a class="menu-row menu-row-clickable w-full" 
								href="/register">
								<div class="flex flex-col gap-1 flex-grow">
									<div>{{$t('user.register')}}</div>
								</div>


								<button :title="$t('user.register')" class="round-button">
									<ArrowRightIcon class="w-3 h-3" />
								</button>
							</a>

						</div>


						<div class="menu-section">

							<a class="menu-row menu-row-clickable w-full" 
								href="/dashboard">

								<WindowIcon class="menu-row-icon" />

								<div class="flex flex-col gap-1 flex-grow">
									<div>{{$t('user.dashboard')}}</div>
									<div class="text-sm text-gray-600 dark:text-gray-400">{{$t('user.dashboard-description')}}</div>
								</div>
								<button :title="$t('puretrack-menu.add-a-tracker')" class="round-button">
									<ArrowRightIcon class="w-3 h-3" />
								</button>
							</a>

							<button class="menu-row menu-row-clickable w-full" 
								@click="app.show=null; app.showLegend='edit-zones'">
								<MapPinIcon class="menu-row-icon" />

								<div class="flex flex-col gap-1 text-left flex-grow">
									<div>{{$t('user.edit-zones')}}</div>
									<div class="text-sm text-gray-600 dark:text-gray-400">{{$t('user.edit-zones-description')}}</div>
								</div>
								<button :title="$t('puretrack-menu.add-a-tracker')" class="round-button">
									<ArrowRightIcon class="w-3 h-3" />
								</button>
							</button>


							<a class="menu-row menu-row-clickable w-full" 
								href="/user/profile">

								<LockOpenIcon class="menu-row-icon" />

								<div class="flex-grow">{{$t('user.change-password')}}</div>
								<button :title="$t('puretrack-menu.add-a-tracker')" class="round-button">
									<ArrowRightIcon class="w-3 h-3" />
								</button>
							</a>


							<a href="/trackers" class="menu-row menu-row-clickable">

								<PlusCircleIcon class="menu-row-icon" />

								<h3 class="flex-grow">{{ $t('puretrack-menu.add-a-tracker') }}</h3> 

								<button :title="$t('puretrack-menu.add-a-tracker')" class="round-button">
									<ArrowRightIcon class="w-3 h-3" />
								</button>
							</a>

							<a class="menu-row menu-row-clickable w-full" 
								href="/subscription">

								<img src="/images/puretrack-logo.png" class="h-auto w-5 ">

								<div class="flex-grow">{{$t('user.manage-pro')}}</div>
								<button :title="$t('puretrack-menu.add-a-tracker')" class="round-button">
									<ArrowRightIcon class="w-3 h-3" />
								</button>
							</a>

						</div>




						<form action="/logout" method="POST" class="w-full menu-button menu-row-clickable">
							<input type="hidden" name="_token" :value="csrf()">
							<button type="submit" class="flex gap-2 justify-center w-full">
								<ArrowRightStartOnRectangleIcon class="menu-row-icon" />
								{{$t('user.logout')}}
							</button>
						</form>



						<div class="menu-section" v-if="app.user?.level==1">

							<a class="menu-row menu-row-clickable w-full" 
								@click.prevent="importOGN()">

								<div class="flex flex-col gap-1">
									<div>Import OGN</div>
								</div>
							</a>

							<a class="menu-row menu-row-clickable w-full" 
								@click.prevent="importADSB()">
								<div class="flex flex-col gap-1">
									<div>Import ADSB</div>
								</div>
							</a>

						</div>




					</div>

				</div>

			</div>


		</div>
	</div>

</template>