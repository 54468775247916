import './bootstrap';
import '../css/map-layers.css';

import { createApp, h } from 'vue';
import App from './MapPages/MapLayoutLayers.vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import spinner  from './MapComponents/Spinner.vue';
import LoadingHorizontal from '@/MapComponentsLayers/LoadingHorizontal.vue';
import { createI18n, useI18n } from 'vue-i18n';

import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';


import en from "./Translations/en.json";
import enUS from "./Translations/en-us.json";
import fr from "./Translations/fr.json";
import de from "./Translations/de.json";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);


const i18n = createI18n({
	legacy: false,
	locale: 'en',
	fallbackLocale: 'en',
	messages: {
		fr,
		en,
		enUS,
		de,
	}
});

const app = createApp(App)
app.use(pinia);
app.use(VueTippy);
app.use(i18n);
app.component('spinner', spinner);
app.component('LoadingHorizontal', LoadingHorizontal);
app.mount("#app");